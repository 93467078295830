<template>
  <section class="addressformsec">
    <form
      @submit.prevent="updating ? submitUpdateAddress() : submitCreateAddress()"
      ref="form"
    >
      <toolbar routename="menu" :title="title" bgcolor="transparent"></toolbar>
      <v-main class="mb-4">
        <v-container class="addressformseccont">
          <v-container>
            <v-alert v-if="successmsg" dense dismissible type="success">{{
              successmsg
            }}</v-alert>
            <div>
              <label>Name</label>
              <v-text-field placeholder="Name" dense v-model="formAddress.contactName" />
            </div>
            <div>
              <label>Contact No</label>
              <v-text-field
                placeholder="eg. 601816281628"
                dense
                v-model="formAddress.contactNo"
              />
            </div>
            <div>
              <label>Address Search</label>
              <v-autocomplete
                v-model="address"
                :items="places"
                :loading="isLoading"
                :search-input.sync="search"
                item-text="description"
                item-value="description"
                placeholder="Start typing to Search"
                prepend-icon="mdi-map-search"
                hide-no-data
                hide-selected
                hide-details
                no-filter
                return-object
              >
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title
                        class="brackettext"
                        v-html="data.item.structured_formatting.main_text"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        class="brackettext"
                        v-html="data.item.structured_formatting.secondary_text"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </div>
            <div>
              <label>Block / Floor / Unit no</label>
              <v-text-field dense v-model="formAddress.location" />
            </div>
            <!-- <div>
              <label>Street</label>
              <v-text-field dense v-model="formAddress.street" />
            </div>
            <div>
              <label>ZipCode</label>
              <v-text-field dense v-model="formAddress.zipcode" />
            </div>
            <div>
              <label>State</label>
              <v-text-field dense v-model="formAddress.state" />
            </div> -->
            <div>
              <v-checkbox
                class="addresscheckbox"
                v-model="formAddress.default"
                label="Set As Default Address"
              ></v-checkbox>
            </div>
          </v-container>
        </v-container>
      </v-main>

      <v-btn
        :disabled="isLoading"
        bottom
        fixed
        :style="{ left: '50%', transform: 'translateX(-50%)' }"
        class="addpadding"
        type="submit"
        color="primary"
      >
        <v-icon v-if="isLoading">mdi-loading</v-icon>
        <template v-else>Save</template>
      </v-btn>
      <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </form>
  </section>
</template>

<script :src="''"></script>
<script>
import toolbar from '@/components/partials/BackToolbar';
import debounce from 'debounce';
import { mapActions, mapState } from 'vuex';

export default {
  props: ['title'],
  components: {
    toolbar,
  },
  data() {
    return {
      updating: false,
      isLoading: false,
      successmsg: null,
      places: [],
      search: null,
      address: null,
      formAddress: {
        contactName: '',
        contactNo: '',
        address: '',
        location: '',
        street: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
        lat: '',
        lng: '',
        url: '',
      },
    };
  },
  computed: {
    ...mapState('config', {
      googleGeoApiKey: (state) => state.googleGeoApiKey,
      distanceMatrixAiKey: (state) => state.distanceMatrixAiKey,
    }),
  },
  methods: {
    ...mapActions('config', ['getAdminOption']),
    ...mapActions('customer', ['createAddress', 'getAddress', 'updateAddress']),
    submitCreateAddress() {
      this.isLoading = true;
      this.createAddress({
        ...this.formAddress,
        default: this.formAddress.default ? 1 : 0,
      })
        .then((res) => {
          if (res) {
            this.successmsg = 'Successfully Added ';
            this.$refs.form.reset();
            this.$router.push('/addresses');
          }
        })
        .catch((er) => {
          console.log(er);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    submitUpdateAddress() {
      this.isLoading = true;
      this.updateAddress({
        id: this.$route.query.addid,
        data: {
          ...this.formAddress,
          default: this.formAddress.default ? 1 : 0,
        },
      })
        .then((res) => {
          if (res) {
            this.successmsg = 'Successfully Updated ';
            this.$refs.form.reset();
            this.$router.push('/addresses');
          }
        })
        .catch((er) => {
          console.log(er);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    parseAddressComponent(addressComponent) {
      let arrCity = [];

      for (const component of addressComponent) {
        if (component.types.includes('country')) {
          this.formAddress.country = component.long_name;
        }

        if (component.types.includes('administrative_area_level_1')) {
          this.formAddress.state = component.long_name;
        }

        if (component.types.includes('locality')) {
          arrCity.push(component.long_name);
        }

        if (component.types.includes('sublocality')) {
          arrCity.unshift(component.long_name);
        }

        if (component.types.includes('route')) {
          this.formAddress.street = component.long_name;
        }

        if (component.types.includes('postal_code')) {
          this.formAddress.zipcode = component.long_name;
        }
      }

      this.formAddress.city = arrCity.join(', ');
    },
  },
  async mounted() {
    if (this.$route.query.addid) {
      this.updating = true;
      this.isLoading = true;
      let result = await this.getAddress(this.$route.query.addid);
      this.isLoading = false;

      if (result.status === 200) {
        let address = result.data;
        this.id = address.id;
        this.formAddress = {
          ...address,
          location: address.locationName,
          default: address.default === 0 ? false : true,
        };
      } else {
        this.$router.push('/addresses');
      }
    }
  },
  async created() {
    await this.getAdminOption({
      key: 'google-geo-api-key',
      params: {
        dayOfWeek: new Date().getDay() + 1,
      },
    });

    await this.getAdminOption({ key: 'distance-matrix-ai-key' });

    // Load google map api script
    if (!document.getElementById('map-google-api')?.value) {
      let googleMapApiScript = document.createElement('script');
      googleMapApiScript.setAttribute('id', 'map-google-api');
      googleMapApiScript.setAttribute(
        'src',
        'https://maps.googleapis.com/maps/api/js?key=' +
          this.googleGeoApiKey +
          '&libraries=places'
      );
      document.head.appendChild(googleMapApiScript);
    }

    setTimeout(() => {
      const google = window.google;

      this.googleAutocompleteService =
        new google.maps.places.AutocompleteService();
      this.googlePlaceService = new google.maps.places.PlacesService(
        new google.maps.Map(document.createElement('div'))
      );
      this.googleSessionToken =
        new google.maps.places.AutocompleteSessionToken();
    }, 500);
  },
  watch: {
    search: debounce(async function (val) {
      if (this.isLoading || val === this.address) return;

      this.isLoading = true;

      if (!val) {
        this.isLoading = false;
        return;
      }

      this.places = null;
      this.googleAutocompleteService.getPlacePredictions(
        {
          input: val,
          componentRestrictions: { country: 'my' },
          sessionToken: this.googleSessionToken,
        },
        (predictions, status) => {
          if (
            status == google.maps.places.PlacesServiceStatus.OK &&
            predictions
          ) {
            this.places = predictions;
          }

          this.isLoading = false;
        }
      );
    }, 700),
    address: debounce(function (place) {
      this.googlePlaceService.getDetails(
        {
          placeId: place.place_id,
          sessionToken: this.googleSessionToken,
        },
        (place, status) => {
          if (
            status === google.maps.places.PlacesServiceStatus.OK &&
            place &&
            place.geometry &&
            place.geometry.location
          ) {
            this.isLoading = true;

            // Set delivery address details
            this.formAddress.address = this.address.description;
            this.formAddress.location = place.name;
            this.formAddress.lat = place.geometry.location.lat();
            this.formAddress.lng = place.geometry.location.lng();
            this.formAddress.url = place.url;
            this.parseAddressComponent(place.address_components);

            this.isLoading = false;
          }
        }
      );
    }, 300),
  },
};
</script>

<style scoped>
.addressesbtngrp .v-btn:before {
  background-color: transparent !important;
}
.addressformsec .addressformseccont .v-btn:not(.v-btn--round).v-size--default {
  color: rgb(207, 207, 207);
  text-transform: capitalize;
}
.v-item--active {
  color: #FF6347 !important;
}
.addresscheckbox label {
  margin: 0;
  font-weight: bold;
}
</style>
